/* eslint-disable no-undef */
export const checkGlanceInterface =
  typeof GlanceAndroidInterface != "undefined" ||
  typeof AndroidUtils != "undefined";

export const checkBridgeInterface =
  typeof GlanceAndroidInterface != "undefined" &&
  typeof AndroidUtils != "undefined";

export const checkOciInterface =
  typeof GlanceAndroidInterface != "undefined" &&
  typeof AndroidUtils != "undefined" &&
  typeof GlanceOciInterface != "undefined";

export const isNetworkConnected = () => {
  if (typeof window.AndroidUtils != "undefined") {
    return window.AndroidUtils.isNetworkConnected();
  }
};

export const isAppInstalled = packageName => {
  if (typeof GlanceAndroidInterface != "undefined") {
    return GlanceAndroidInterface.isAppInstalled(packageName);
  }
};

export const userEngaged = () => {
  if (typeof GlanceAndroidInterface != "undefined") {
    GlanceAndroidInterface.userEngaged();
  }
};

export const getGlanceBackgroundImageUri = () => {
  if (typeof GlanceAndroidInterface != "undefined") {
    return GlanceAndroidInterface.getGlanceBackgroundImageUri();
  }
};

export const isKeyguardLocked = () => {
  if (typeof AndroidUtils != "undefined") {
    return AndroidUtils.isKeyguardLocked();
  }
};

// --------- For Xiaomi -------
export const launchIntentAfterUnlock = (intentAction, intentData) => {
  if (typeof GlanceAndroidInterface != "undefined") {
    GlanceAndroidInterface.launchIntentAfterUnlock(intentAction, intentData);
  }
};

export const enableGlance = referrer => {
  if (typeof AndroidBridge != "undefined") {
    AndroidBridge.enableGlance(referrer);
  }
};

export const launchHomeScreen = referrer => {
  if (typeof AndroidBridge != "undefined") {
    AndroidBridge.launchHomeScreen(referrer);
  }
};

export const launchIntent = (intentAction, intentData) => {
  if (typeof GlanceAndroidInterface != "undefined") {
    GlanceAndroidInterface.launchIntent(intentAction, intentData);
  }
};

export const getGlanceSdkVersion = () => {
  if (typeof window.GlanceAndroidInterface != "undefined") {
    return window.GlanceAndroidInterface.getGlanceSdkVersion();
  }
};

// --------- For Samsung -------
export const launchAppAfterUnlock = (appPackageName, deepLinkUrl) => {
  if (typeof GlanceAndroidInterface != "undefined") {
    GlanceAndroidInterface.launchAppAfterUnlock(appPackageName, deepLinkUrl);
  }
};

export const launchApp = (appPackageName, deepLinkUrl) => {
  if (typeof GlanceAndroidInterface != "undefined") {
    GlanceAndroidInterface.launchApp(appPackageName, deepLinkUrl);
  }
};

export const singleAppInstallService = (data, configData) => {
  if (typeof window.GlanceOciInterface != "undefined") {
    return window.GlanceOciInterface.singleAppInstallService(data, configData);
  }
};

export const appInstallService = data => {
  if (typeof window.GlanceAndroidInterface != "undefined") {
    return window.GlanceAndroidInterface.appInstallService(data);
  }
};

export const sendCustomAnalyticsEvent = (eventType, extras) => {
  if (
    typeof window.GlanceAndroidInterface != "undefined" &&
    typeof window.GlanceAndroidInterface.sendCustomAnalyticsEvent != "undefined"
  ) {
    return window.GlanceAndroidInterface.sendCustomAnalyticsEvent(
      eventType,
      extras
    );
  }
};

export const launchAppFallBack = (
  JIO_CINEMAS_WEB,
  JIO_CINEMAS,
  appPackageName,
  deepLinkUrl,
  sendAnalytics
) => {
  if (checkBridgeInterface) {
    const source = "EGC";
    if (isAppInstalled(appPackageName)) {
      if (isKeyguardLocked()) {
        if (
          typeof GlanceAndroidInterface != "undefined" &&
          typeof GlanceAndroidInterface.launchAppAfterUnlock != "undefined"
        ) {
          GlanceAndroidInterface.launchAppAfterUnlock(
            appPackageName,
            deepLinkUrl
          );
        } else launchIntentAfterUnlock(null, JIO_CINEMAS_WEB);
      } else {
        if (
          typeof GlanceAndroidInterface != "undefined" &&
          typeof GlanceAndroidInterface.launchApp != "undefined"
        ) {
          GlanceAndroidInterface.launchApp(appPackageName, deepLinkUrl);
        } else launchIntent(null, JIO_CINEMAS_WEB);
      }
      sendAnalytics("opening_app", appPackageName, source);
    } else {
      sendAnalytics("opening_playstore", appPackageName, source);
      launchIntentAfterUnlock(null, JIO_CINEMAS);
    }
  }
};
