import { getParameterByName } from "./tracking";

// export const webpSupport = process.browser
//   ? new Promise(function(resolve) {
//       const img = new Image();
//       img.src = "/static/images/1x1.webp";
//       img.onload = () => resolve(true);
//       img.onerror = () => resolve(false);
//     })
//   : Promise.resolve(false);

// TODO: Refactor to normal boolean
export const webpSupport = Promise.resolve(true);

export const isSafari = () => {
  if (process.browser) {
    if (
      window.navigator.userAgent.indexOf("Safari") !== -1 &&
      window.navigator.userAgent.indexOf("Chrome") === -1 &&
      window.navigator.userAgent.indexOf("Chromium") === -1
    ) {
      return true;
    }
  }
  return false;
};

export const isOnb = () => {
  let btnName = false;
  if (process.browser) {
    btnName = window.location.href.indexOf("onb=true") > -1 ? true : false;
  }
  return btnName;
};

export const isSourceIntegration = () => {
  let sourceIntegration = false;
  if (typeof window !== "undefined") {
    sourceIntegration =
      getParameterByName("source", window?.location?.href) ===
      "api-integration";
  }
  return sourceIntegration;
};

export const storeSessionStorage = (key, value) => {
  if (typeof window !== "undefined") {
    window.sessionStorage.setItem(key, value);
  }
};

export const getSessionStorage = key => {
  if (typeof window !== "undefined") {
    return window.sessionStorage.getItem(key);
  }
};

export const getViewPortDimensions = () => {
  let vw, vh;
  if (process.browser) {
    vw =
      Math.max(
        window.document.documentElement.clientWidth,
        window.innerWidth || 0
      ) + "px";
    vh =
      Math.max(
        window.document.documentElement.clientHeight,
        window.innerHeight || 0
      ) + "px";
  } else {
    vw = "100vw";
    vh = "100vh";
  }
  return {
    vw,
    vh
  };
};
