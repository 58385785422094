import Router from "next/router";
import getConfig from "next/config";
import { isSourceIntegration } from "./browser";
import {
  checkBridgeInterface,
  isAppInstalled,
  isKeyguardLocked,
  isNetworkConnected,
  sendCustomAnalyticsEvent
} from "./glanceJsBridges";
import { JIO_APP_DATA } from "../common/constants";

let ReactGA;
let goldenGateBridge;
let isReactGAloaded = false;

Router.events.on("routeChangeComplete", () => pageView());
const { publicRuntimeConfig } = getConfig();

export const initGA = async trackingID => {
  if (!publicRuntimeConfig.ga) {
    return;
  }
  if (!isReactGAloaded) {
    await loadReactGA();
  }
  ReactGA.initialize(trackingID, {
    gaOptions: {
      siteSpeedSampleRate: 100
    }
  });
  pageView();
};

export const pageView = () => {
  if (!publicRuntimeConfig.ga || isSourceIntegration()) {
    return;
  }
  ReactGA.pageview(window.location.pathname + window.location.search);
};

export const sendAnalytics = (action, app, source = "EGC") => {
  const eventType = "single_app";
  if (checkBridgeInterface) {
    const isNetworkConnectedFlag = isNetworkConnected();
    const extras = {
      action,
      islock_state: isKeyguardLocked(),
      isNetworkConnectedFlag,
      is_app_installed: isAppInstalled(JIO_APP_DATA.data.packageName),
      app,
      source
    };
    sendCustomAnalyticsEvent(eventType, JSON.stringify(extras));
  } else {
    fireEvent(action, app, source);
  }
};

export const fireEvent = async (category, action, label, isInteractive) => {
  let origin = "";
  if (!isReactGAloaded) {
    await loadReactGA();
  }
  if (process.browser) {
    origin = goldenGateBridge() ? "App" : "Web";
  }
  if (!publicRuntimeConfig.ga || isSourceIntegration()) {
    return console.debug({
      category: category + origin,
      action: action,
      label: label ? label.toString() : "",
      nonInteraction: isInteractive ? isInteractive : false
    });
  }
  ReactGA.event({
    category: category + origin,
    action: action,
    label: label ? label.toString() : "",
    nonInteraction: isInteractive ? isInteractive : false
  });
};

/* eslint-disable no-undef */
export const fireFbPageView = () => {
  if (!publicRuntimeConfig.pixels || isSourceIntegration()) {
    return;
  }
  fbq("track", "ViewContent");
};

export const fireTakeTourPixels = () => {
  if (!publicRuntimeConfig.pixels || isSourceIntegration()) {
    return;
  }
  fbq("track", "Lead");
  gtag("event", "conversion", {
    send_to: "AW-449077259/iUKbCODW8_ABEIvAkdYB"
  });
};

export const fireStartNowPixels = () => {
  if (!publicRuntimeConfig.pixels || isSourceIntegration()) {
    return;
  }
  fbq("track", "StartTrial", {
    value: "0.00",
    currency: "USD",
    predicted_ltv: "0.00"
  });
  gtag("event", "conversion", {
    send_to: "AW-449077259/2u5PCNXU8_ABEIvAkdYB"
  });
};

export const trackWebVitals = async metric => {
  let origin = "";
  if (!isReactGAloaded) {
    await loadReactGA();
  }
  if (process.browser) {
    origin = goldenGateBridge() ? "App" : "Web";
  }
  const category = "RUM Metrics - ";
  if (publicRuntimeConfig.ga && !isSourceIntegration()) {
    ReactGA.event({
      category: category + origin,
      action: metric.name,
      label: metric.id.toString(),
      value: Math.round(
        metric.name === "CLS" ? metric.value * 1000 : metric.value
      ), // values must be integers
      nonInteraction: true
    });
  }
};

export const getParameterByName = (nameParam, url) => {
  const name = nameParam.replace(/[\]]/g, "\\$&");
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

const loadReactGA = async () => {
  if (!isSourceIntegration()) {
    const { default: DynamicReactGA } = await import("react-ga");
    ReactGA = DynamicReactGA;
  }
  const { hasGoldenGateBridge } = await import("./goldengatebridge");
  goldenGateBridge = hasGoldenGateBridge;
  isReactGAloaded = true;
};

/* eslint-enable no-undef */
