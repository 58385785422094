import NextI18Next from "next-i18next";
import path from "path";
import getConfig from "next/config";

const { localeSubpaths } = getConfig().publicRuntimeConfig;

export default new NextI18Next({
  defaultLanguage: "en",
  fallbackLng: "en",
  otherLanguages: ["ta", "hi", "en", "te", "es", "pt", "id"],
  localeSubpaths,
  localePath: path.resolve("./static/locales")
});
