export const overlayScreen = Object.freeze({
  DefaultScreen: 0,
  FollowClickPopup: 1,
  ProfileClickPopup: 2,
  CoinClickPopup: 3,
  CreateVideoClick: 4
});

export const TRY_ROPOSO_URL = "https://dl.roposo.com/tryroposo";

export const CREATE_VIDEO_TITLE = "Create video and become Star on Roposo";
export const CREATE_VIDEO_DESCRIPTION =
  "Join 5 Crore Indians on India ka Apna video app.";

export const PROFILE_CLICK_TITLE = "Create your profile on Roposo";
export const PROFILE_CLICK_DESCRIPTION =
  "Watch videos, Join 5 Crore Indians and celebrities.";
export const COIN_CLICK_TITLE = "Earn money on Roposo";
export const COIN_CLICK_DESCRIPTION =
  "1 Lac Indians are earning money from home.";

export const BUZZSTORM_FULL_ANIMATION_DURATION = "2s";

export const BUZZSTORM_ANIMATION_DURATION = "0.6s";

export const PLAYSTORE_APP_LINK =
  "https://play.google.com/store/apps/details?id=com.inmobi.lockscreen.glance";

export const TURN_ON_GLANCE = `Turn on Glance to`;

export const GLANCE_TEXT = `Enjoy live entertainment, news, sports, games and shoppping on your lockscreen`;

export const ACTIVATION_TIME_UP_TEXT = `Want to continue enjoying Glance?`;

export const ACTIVATION_POP_UP_TEXT =
  "Turn on to get exciting content on your lockscreen";

export const ACTIVATION_BUTTON_TEXT = `Turn On Glance`;

export const BUTTON_TEXT = `Turn On Now`;

export const POP_UP_TEXT = `Please turn on Glance to enjoy this content at any time and receive it directly on your lockscreen.`;

export const TIME_UP_TEXT = `Oops! You have reached the limit!`;

export const PRIVACY_POLICY = "https://glance.com/privacy-policy";

export const EULA = "https://glance.com/eula";

export const LATAM_REGIONS = ["BR", "MX", "CO"];

export const JIO_CINEMAS_WEB = "https://www.jiocinema.com";

export const JIO_CINEMAS =
  "https://play.google.com/store/apps/details?id=com.jio.media.ondemand&hl=en_IN&gl=US&pli=1";

export const XIAOMI_PACKAGE = [
  "com.miui.android.fashiongallery",
  "glance.sample.standalone.testbed.xiaomi",
  "glance.sample.standalone.xiaomi"
];

export const SDK_VERSION_816 = 81600;

export const SDK_VERSION_8_20_0 = 82000;

export const JIO_APP_DATA = {
  meta: { templateType: "single-app", version: "v1" },
  data: {
    isHighlights: true,
    showRecursiveScreen: false,
    appName: "JioCinema",
    packageName: "com.jio.media.ondemand",
    installLater: true,
    enableNewConfigScreen: true,
    appBeacons: {
      appSubmitBeacons: [""],
      installCompleteBeacons: [
        "https://beacons.glance.inmobi.com/beacon/install_complete?af_c_id=GLNCOCI&af_adset=$GLANCE_ID&af_adset_id=$GLANCE_ID&af_ad=$GLANCE_ID&af_ad_id=$GLANCE_ID&af_siteid=GLNCOCI&c=inmobi&pid=inmobi_int&click_id=gl_$IMP_ID:$USER_ID:$GLANCE_ID:$IMP_TS&advertising_id=$GPID&thirdPartyBeaconHost=app.appsflyer.com&thirdPartyBeaconProtocol=https&thirdPartyBeaconPath=/com.jio.media.ondemand&glc_glcid=$GLANCE_ID&glc_gpid=$GPID&gl_impressionid=$IMP_ID&gl_timestamp=$IMP_TS&glc_apikey=30aedfec48ddd7c42cb8cd855b431a774a0d6b17&glc_packageid=com.jio.media.ondemand&glc_userid=$USER_ID"
      ],
      notificationTapBeacons: [""]
    },
    brandColor: { lightTone: "#9a43fb", darkTone: "#4b21ff" },
    tags: {
      imgUrl: "crown.png",
      text1: "Popular Short Video App",
      tagBgColor: "#134a9e"
    },
    stars: 3.8,
    reviews: 873000,
    appSize: 14,
    appSizeUnit: "MB",
    downloads: 100000000,
    downloadTitle: "Downloads",
    categoryRank: 7,
    category: "Entertainment",
    appLogo: "jiocinema.png",
    toastMessageOnline: "App will install in next unlock.",
    toastMessageOffline: "Internet is off",
    carousel: [
      { description: "First Image", path: "carousel1.jpg", type: "image" },
      { description: "Second Image", path: "carousel2.jpg", type: "image" },
      { description: "Third Image", path: "carousel3.jpg", type: "image" }
    ],
    recursiveMsg1: "Install Unsuccessful",
    recursiveMsg2: "10Cr users are using Moj",
    ctaBeaconUrl:
      "https://beacons.glance.inmobi.com/beacon/cta?af_c_id=GLNCOCI&af_adset=$GLANCE_ID&af_adset_id=$GLANCE_ID&af_ad=$GLANCE_ID&af_ad_id=$GLANCE_ID&af_siteid=GLNCOCI&c=inmobi&pid=inmobi_int&click_id=gl_$IMP_ID:$USER_ID:$GLANCE_ID:$IMP_TS&advertising_id=$GPID&thirdPartyBeaconHost=app.appsflyer.com&thirdPartyBeaconProtocol=https&thirdPartyBeaconPath=/com.jio.media.ondemand&glc_glcid=$GLANCE_ID&glc_gpid=$GPID&gl_impressionid=$IMP_ID&gl_timestamp=$IMP_TS&glc_apikey=30aedfec48ddd7c42cb8cd855b431a774a0d6b17&glc_packageid=com.jio.media.ondemand&glc_userid=$USER_ID",
    installNowVisibility: true,
    GlanceAndroidInterfaceInBrowser: false,
    autoAppOpen: true,
    autoAppOpenDelay: 3,
    appInstallStartDelay: 3,
    nudgeConfig: {
      showNudge: true,
      hardwareExhilaration: false,
      nudgeMode: false,
      webURL: "https://www.glance.com/",
      nudgeCtaText: "Open App",
      nudgeDesc: "App Details Description"
    }
  }
};
