import Router from "next/router";

Router.events.on("routeChangeStart", () => {
  const loaderElem = document.getElementById("loader");
  if (loaderElem) {
    loaderElem.style.display = "block";
  }
});
Router.events.on("routeChangeComplete", () => {
  const loaderElem = document.getElementById("loader");
  if (loaderElem) {
    loaderElem.style.display = "none";
  }
});
Router.events.on("routeChangeError", () => {
  const loaderElem = document.getElementById("loader");
  if (loaderElem) {
    loaderElem.style.display = "none";
  }
});
