import React from "react";
import getConfig from "next/config";
const PixelTags = () => {
  const { publicRuntimeConfig } = getConfig();
  if (!publicRuntimeConfig.pixels) {
    return null;
  }
  return (
    <>
      <script
        dangerouslySetInnerHTML={{
          __html:
            "!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,document,'script', 'https://connect.facebook.net/en_US/fbevents.js');fbq('init', '606715683194865');fbq('track', 'PageView');"
        }}
      ></script>
      <noscript>
        <img
          height="1"
          width="1"
          src="https://www.facebook.com/tr?id=606715683194865&ev=PageView&noscript=1"
          alt="facebook pixel"
        />
      </noscript>
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=AW-449077259"
      ></script>
      <script
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date());gtag('config', 'AW-449077259');`
        }}
      ></script>
      <script
        dangerouslySetInnerHTML={{
          __html: `gtag('event', 'conversion', {'send_to': 'AW-449077259/7dXACKK3k_EBEIvAkdYB'});`
        }}
      ></script>
    </>
  );
};

export default PixelTags;
