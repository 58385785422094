import "./../utils/loader";

import { Global, css } from "@emotion/core";

import App from "next/app";
import Head from "next/head";
import PixelTags from "../components/BuzzStorm/PixelTags";
import React from "react";
import globalStyles from "../common/global-styles";
import i18n from "../../../i18n";

class Main extends App {
  static async getInitialProps({ Component, ctx }) {
    let pageProps = {};
    let device = null,
      isGlanceSupported = false,
      enableGlanceUrl = false,
      existingUser = false,
      isSamsungDeviceLSS = false,
      isSamsungDeviceDLSS = false,
      userCountryCode = null,
      browser = null,
      referer = null,
      oem = null,
      deviceType = null,
      onb = null,
      isSourceIntegration = false;
    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
      if (ctx && ctx.req) {
        device = ctx.req.device ? ctx.req.device.type : null;
        browser = ctx.req.browser;
        isGlanceSupported = ctx.req.isGlanceSupported;
        isSamsungDeviceLSS = ctx.req.isSamsungDeviceLSS;
        isSamsungDeviceDLSS = ctx.req.isSamsungDeviceDLSS;
        enableGlanceUrl = ctx.req.enableGlanceUrl;
        existingUser = ctx.req.cookies && ctx.req.cookies.e_u ? true : false;
        userCountryCode = ctx.req.userCountryCode;
        referer = ctx.req.referer;
        oem = ctx.req.oem;
        deviceType = ctx.req.deviceType;
        onb = ctx.req.query.onb;
        isSourceIntegration = ctx.req.query.source === "api-integration";
      }
    }
    return {
      pageProps: typeof pageProps === "undefined" ? {} : pageProps,
      device,
      isGlanceSupported,
      isSamsungDeviceLSS,
      isSamsungDeviceDLSS,
      enableGlanceUrl,
      existingUser,
      userCountryCode,
      referer,
      browser,
      oem,
      onb,
      deviceType,
      isSourceIntegration
    };
  }
  render() {
    const {
      Component,
      pageProps,
      device,
      isSamsungDeviceLSS,
      isSamsungDeviceDLSS,
      isGlanceSupported,
      enableGlanceUrl,
      existingUser,
      userCountryCode,
      referer,
      browser,
      oem,
      onb,
      deviceType,
      isSourceIntegration
    } = this.props;
    return (
      <>
        <Head>
          <meta key="charSet" charSet="utf-8" />
          <title key="title">Glance - The Internet Starts here</title>
          <link
            key="favicon"
            rel="shortcut icon"
            href="/static/icons/favicon.ico"
          />
          <meta
            key="viewport"
            name="viewport"
            content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, maximum-scale=1, user-scalable=no"
          />
          <meta key="theme-color" name="theme-color" content="#000000" />
          <link key="manifest" rel="manifest" href="/static/manifest.json" />
          <meta
            key="og:title"
            property="og:title"
            content="Glance - The Internet Starts here"
          />
          <meta key="og:url" property="og:url" content="/" />
          <meta
            key="og:image"
            property="og:image"
            content="/static/images/favicon.ico"
          />
          <meta key="og:image:width" property="og:image:width" content="400" />
          <meta
            key="og:image:height"
            property="og:image:height"
            content="400"
          />
          <script
            async
            src="https://cdn.jsdelivr.net/npm/pwacompat@2.0.9/pwacompat.min.js"
            integrity="sha384-VcI6S+HIsE80FVM1jgbd6WDFhzKYA0PecD/LcIyMQpT4fMJdijBh0I7Iblaacawc"
            crossOrigin="anonymous"
          />
          <link rel="preconnect" href="https://web.glance-cdn.com" />
          <link rel="preconnect" href="https://x-in.glance-cdn.com" />
        </Head>
        {!isSourceIntegration && <PixelTags />}
        <Global
          styles={css`
            .headline-extra-large {
              font-size: 48px;
              font-weight: 900;
              color: #000;
              line-height: 1.2;
              margin: 20px 0px 20px;
            }
            .headline-large {
              font-size: 40px;
              font-weight: 900;
              color: #000;
              line-height: 1.19;
            }
            .headline-medium {
              font-size: 32px;
              font-weight: 900;
              color: #000;
              line-height: 1.31;
            }
            .headline-small {
              font-size: 24px;
              font-weight: 900;
              color: #000;
              line-height: 1.41;
            }
            .title-large {
              font-size: 16px;
              font-weight: 900;
              color: #000;
              line-height: 1.5;
            }
            .title-small {
              font-size: 14px;
              font-weight: 900;
              color: #000;
              line-height: 1.6;
            }
            .body-large {
              font-size: 16px;
              font-weight: 300;
              color: #000;
              line-height: 1.6;
            }
            .body-small {
              font-size: 12px;
              font-weight: 300;
              color: #000;
              line-height: 1.6;
            }
            .width100 {
              width: 100%;
            }
            .primary-color-100 {
              color: #fc024d;
            }
            ${globalStyles}
          `}
        />
        <Component
          {...pageProps}
          device={device}
          isGlanceSupported={isGlanceSupported}
          isSamsungDeviceLSS={isSamsungDeviceLSS}
          isSamsungDeviceDLSS={isSamsungDeviceDLSS}
          enableGlanceUrl={enableGlanceUrl}
          existingUser={existingUser}
          userCountryCode={userCountryCode}
          referer={referer}
          browser={browser}
          oem={oem}
          deviceType={deviceType}
          onb={onb}
        />
      </>
    );
  }
}
export default i18n.appWithTranslation(Main);
