// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import { BrowserTracing } from "@sentry/tracing";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn:
      SENTRY_DSN ||
      "https://92a4cae2752f4cb0bab2fa243324e27d@o1160911.ingest.sentry.io/6417338",
    // Adjust this value in production, or use tracesSampler for greater control
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.01,
    sampleRate: 0.1,
    ignoreErrors: [
      "getReadModeConfig",
      "getReadModeExtract",
      "getReadModeRender"
    ],
    environment: process.env.NODE_ENV
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
}
